<nav>
  <div class="navbar-container container">
    <div class="logo-container">
      <a href="/{{ currentLanguage }}" class="logo">
        <img
          src="assets/images/png/logo-ideku-black.png"
          width="100"
          alt="ideku"
          fetchpriority="low"
          loading="lazy"
        />
      </a>
    </div>
    <button
      class="btn-toggle"
      type="button"
      (click)="isCollapsed = !isCollapsed"
    >
      <svg
        width="30"
        height="20"
        viewBox="0 0 30 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="30" height="4" fill="#404040" />
        <rect y="8" width="30" height="4" fill="#404040" />
        <rect y="16" width="30" height="4" fill="#404040" />
      </svg>
    </button>
    <div
      class="nav-link-wrapper"
      [ngStyle]="{
        left: !isMobile || (isMobile && isCollapsed) ? '0' : '999px'
      }"
    >
      <ul class="nav-link-container" *ngIf="!hideMenu">
        <li class="link product-dropdown">
          <a
            href="javascript:void(0)"
            (click)="dropdownClick('product')"
            class="ttl-link"
          >
            {{ "NAV_MENU.PRODUCT" | translate }}
            <svg
              *ngIf="!isMobile"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_5238_1931)">
                <path
                  d="M10.5492 4.66059L6.34922 8.2713C6.29922 8.31416 6.24505 8.34445 6.18672 8.36216C6.12839 8.38016 6.06589 8.38916 5.99922 8.38916C5.93255 8.38916 5.87005 8.38016 5.81172 8.36216C5.75339 8.34445 5.69922 8.31416 5.64922 8.2713L1.43672 4.66059C1.32005 4.56059 1.26172 4.43559 1.26172 4.28559C1.26172 4.13559 1.32422 4.00702 1.44922 3.89988C1.57422 3.79273 1.72005 3.73916 1.88672 3.73916C2.05339 3.73916 2.19922 3.79273 2.32422 3.89988L5.99922 7.04987L9.67422 3.89987C9.79089 3.79987 9.93455 3.74987 10.1052 3.74987C10.2762 3.74987 10.4242 3.80345 10.5492 3.91059C10.6742 4.01773 10.7367 4.14273 10.7367 4.28559C10.7367 4.42845 10.6742 4.55345 10.5492 4.66059Z"
                  fill="#404040"
                />
              </g>
              <defs>
                <clipPath id="clip0_5238_1931">
                  <rect
                    width="10.2857"
                    height="12"
                    fill="white"
                    transform="translate(0 11.1428) rotate(-90)"
                  />
                </clipPath>
              </defs>
            </svg>
          </a>
          <div class="dropdown-content" *ngIf="showProductDropdown || isMobile">
            <div class="dropdown-grid">
              <a
                *ngFor="
                  let link of sortBy('order', productFeatureLists);
                  let i = index
                "
                routerLink="/{{ currentLanguage }}/{{ link.value }}"
                class="dropdown-item"
                [ngClass]="{ disabled: !link.available }"
                (click)="!link.available && $event.preventDefault()"
              >
                <img
                  *ngIf="link.image.length > 0"
                  [src]="link.image"
                  [alt]="'icon ' + link.name"
                  fetchpriority="low"
                  loading="lazy"
                  width="16"
                />
                <p>
                  <span class="link-name">{{ link.name }} </span>
                  <span *ngIf="!link.available" class="coming-soon-txt"
                    >*Coming Soon</span
                  >
                </p>
              </a>
            </div>
          </div>
        </li>
        <li class="link">
          <a
            class="nav-link ttl-link"
            routerLink="/{{ currentLanguage }}/about-us"
            routerLinkActive="active"
            >{{ "NAV_MENU.ABOUT_US" | translate }}</a
          >
        </li>
        <li class="link integration-dropdown">
          <a
            class="nav-link ttl-link"
            href="javascript:void(0)"
            (click)="dropdownClick('integration')"
          >
            {{ "NAV_MENU.INTEGRATION" | translate }}
            <svg
              *ngIf="!isMobile"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_5238_1931)">
                <path
                  d="M10.5492 4.66059L6.34922 8.2713C6.29922 8.31416 6.24505 8.34445 6.18672 8.36216C6.12839 8.38016 6.06589 8.38916 5.99922 8.38916C5.93255 8.38916 5.87005 8.38016 5.81172 8.36216C5.75339 8.34445 5.69922 8.31416 5.64922 8.2713L1.43672 4.66059C1.32005 4.56059 1.26172 4.43559 1.26172 4.28559C1.26172 4.13559 1.32422 4.00702 1.44922 3.89988C1.57422 3.79273 1.72005 3.73916 1.88672 3.73916C2.05339 3.73916 2.19922 3.79273 2.32422 3.89988L5.99922 7.04987L9.67422 3.89987C9.79089 3.79987 9.93455 3.74987 10.1052 3.74987C10.2762 3.74987 10.4242 3.80345 10.5492 3.91059C10.6742 4.01773 10.7367 4.14273 10.7367 4.28559C10.7367 4.42845 10.6742 4.55345 10.5492 4.66059Z"
                  fill="#404040"
                />
              </g>
              <defs>
                <clipPath id="clip0_5238_1931">
                  <rect
                    width="10.2857"
                    height="12"
                    fill="white"
                    transform="translate(0 11.1428) rotate(-90)"
                  />
                </clipPath>
              </defs>
            </svg>
          </a>
          <div
            class="dropdown-content-nested"
            *ngIf="showIntegrationDropdown || isMobile"
          >
            <app-dropdown [menuItems]="menuIntegrationItems"></app-dropdown>
          </div>
        </li>
        <li class="link">
          <a class="nav-link ttl-link" href="https://blog.ideku.id/">{{
            "NAV_MENU.BLOG" | translate
          }}</a>
        </li>
        <li class="link">
          <a
            class="nav-link ttl-link"
            [class.text-white]="isCareer"
            routerLink="/{{ currentLanguage }}/career"
            routerLinkActive="active"
            >{{ "NAV_MENU.CAREER" | translate }}</a
          >
        </li>
      </ul>

      <div class="util-container" *ngIf="!hideMenu">
        <div class="nav-item page dropdown dropdown-language language-dropdown">
          <a
            class="nav-link"
            href="javascript:void(0)"
            (click)="dropdownClick('language')"
          >
            <ng-container *ngIf="currentLanguage === 'id'">
              <img
                src="assets/images/logo/id-logo.png"
                alt="logo Indonesia"
                fetchpriority="low"
                loading="lazy"
              />
              ID
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                [style.transform]="isMobile ? 'rotate(180deg)' : 'none'"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_5238_1931)">
                  <path
                    d="M10.5492 4.66059L6.34922 8.2713C6.29922 8.31416 6.24505 8.34445 6.18672 8.36216C6.12839 8.38016 6.06589 8.38916 5.99922 8.38916C5.93255 8.38916 5.87005 8.38016 5.81172 8.36216C5.75339 8.34445 5.69922 8.31416 5.64922 8.2713L1.43672 4.66059C1.32005 4.56059 1.26172 4.43559 1.26172 4.28559C1.26172 4.13559 1.32422 4.00702 1.44922 3.89988C1.57422 3.79273 1.72005 3.73916 1.88672 3.73916C2.05339 3.73916 2.19922 3.79273 2.32422 3.89988L5.99922 7.04987L9.67422 3.89987C9.79089 3.79987 9.93455 3.74987 10.1052 3.74987C10.2762 3.74987 10.4242 3.80345 10.5492 3.91059C10.6742 4.01773 10.7367 4.14273 10.7367 4.28559C10.7367 4.42845 10.6742 4.55345 10.5492 4.66059Z"
                    fill="#404040"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5238_1931">
                    <rect
                      width="10.2857"
                      height="12"
                      fill="white"
                      transform="translate(0 11.1428) rotate(-90)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </ng-container>

            <ng-container *ngIf="currentLanguage === 'en'">
              <img
                src="assets/images/logo/sg-logo.png"
                alt="logo Singapore"
                fetchpriority="low"
                loading="lazy"
              />
              SG (EN)
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                [style.transform]="isMobile ? 'rotate(180deg)' : 'none'"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_5238_1931)">
                  <path
                    d="M10.5492 4.66059L6.34922 8.2713C6.29922 8.31416 6.24505 8.34445 6.18672 8.36216C6.12839 8.38016 6.06589 8.38916 5.99922 8.38916C5.93255 8.38916 5.87005 8.38016 5.81172 8.36216C5.75339 8.34445 5.69922 8.31416 5.64922 8.2713L1.43672 4.66059C1.32005 4.56059 1.26172 4.43559 1.26172 4.28559C1.26172 4.13559 1.32422 4.00702 1.44922 3.89988C1.57422 3.79273 1.72005 3.73916 1.88672 3.73916C2.05339 3.73916 2.19922 3.79273 2.32422 3.89988L5.99922 7.04987L9.67422 3.89987C9.79089 3.79987 9.93455 3.74987 10.1052 3.74987C10.2762 3.74987 10.4242 3.80345 10.5492 3.91059C10.6742 4.01773 10.7367 4.14273 10.7367 4.28559C10.7367 4.42845 10.6742 4.55345 10.5492 4.66059Z"
                    fill="#404040"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5238_1931">
                    <rect
                      width="10.2857"
                      height="12"
                      fill="white"
                      transform="translate(0 11.1428) rotate(-90)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </ng-container>

            <ng-container *ngIf="currentLanguage === 'zh-TW'">
              <img
                src="assets/images/logo/tw-logo.svg"
                alt="logo taiwan"
                fetchpriority="low"
                loading="lazy"
                width="20px"
              />
              繁體中文
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                [style.transform]="isMobile ? 'rotate(180deg)' : 'none'"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_5238_1931)">
                  <path
                    d="M10.5492 4.66059L6.34922 8.2713C6.29922 8.31416 6.24505 8.34445 6.18672 8.36216C6.12839 8.38016 6.06589 8.38916 5.99922 8.38916C5.93255 8.38916 5.87005 8.38016 5.81172 8.36216C5.75339 8.34445 5.69922 8.31416 5.64922 8.2713L1.43672 4.66059C1.32005 4.56059 1.26172 4.43559 1.26172 4.28559C1.26172 4.13559 1.32422 4.00702 1.44922 3.89988C1.57422 3.79273 1.72005 3.73916 1.88672 3.73916C2.05339 3.73916 2.19922 3.79273 2.32422 3.89988L5.99922 7.04987L9.67422 3.89987C9.79089 3.79987 9.93455 3.74987 10.1052 3.74987C10.2762 3.74987 10.4242 3.80345 10.5492 3.91059C10.6742 4.01773 10.7367 4.14273 10.7367 4.28559C10.7367 4.42845 10.6742 4.55345 10.5492 4.66059Z"
                    fill="#404040"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5238_1931">
                    <rect
                      width="10.2857"
                      height="12"
                      fill="white"
                      transform="translate(0 11.1428) rotate(-90)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </ng-container>

            <ng-container *ngIf="currentLanguage === 'zh-CN'">
              <img
                src="assets/images/logo/cn-logo.svg"
                alt="logo cina"
                fetchpriority="low"
                loading="lazy"
                width="20px"
              />
              简体中文
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                [style.transform]="isMobile ? 'rotate(180deg)' : 'none'"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_5238_1931)">
                  <path
                    d="M10.5492 4.66059L6.34922 8.2713C6.29922 8.31416 6.24505 8.34445 6.18672 8.36216C6.12839 8.38016 6.06589 8.38916 5.99922 8.38916C5.93255 8.38916 5.87005 8.38016 5.81172 8.36216C5.75339 8.34445 5.69922 8.31416 5.64922 8.2713L1.43672 4.66059C1.32005 4.56059 1.26172 4.43559 1.26172 4.28559C1.26172 4.13559 1.32422 4.00702 1.44922 3.89988C1.57422 3.79273 1.72005 3.73916 1.88672 3.73916C2.05339 3.73916 2.19922 3.79273 2.32422 3.89988L5.99922 7.04987L9.67422 3.89987C9.79089 3.79987 9.93455 3.74987 10.1052 3.74987C10.2762 3.74987 10.4242 3.80345 10.5492 3.91059C10.6742 4.01773 10.7367 4.14273 10.7367 4.28559C10.7367 4.42845 10.6742 4.55345 10.5492 4.66059Z"
                    fill="#404040"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5238_1931">
                    <rect
                      width="10.2857"
                      height="12"
                      fill="white"
                      transform="translate(0 11.1428) rotate(-90)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </ng-container>
          </a>
          <div class="dropdown-content" *ngIf="showLanguageDropdown">
            <a
              href="javascript:void(0)"
              *ngFor="let lang of sortBy('order', languages)"
              (click)="setLanguage(lang.value, true)"
            >
              <img
                *ngIf="lang.image.length > 0"
                [src]="lang.image"
                [alt]="'language ' + lang.name"
                fetchpriority="low"
                loading="lazy"
                width="20"
              />
              <p>{{ lang.name }}</p>
            </a>
          </div>
        </div>

        <div class="nav-item page" *ngIf="!isIdepage">
          <button
            class="btn-gradient-danger"
            routerLink="/{{ currentLanguage }}/contact-us"
          >
            {{ "NAV_MENU.CONTACT_US" | translate }}
          </button>
        </div>

        <div class="idepage-btn-wrapper" *ngIf="isIdepage">
          <div class="nav-item nav-btn">
            <a class="nav-link" href="https://idepage.io/my-portal/login">
              <button class="outlined-button">
                {{ "NAV_MENU.LOGIN_BUTTON" | translate }}
              </button>
            </a>
          </div>
  
          <div class="nav-item nav-btn">
            <a class="nav-link" href="https://idepage.io/my-portal/splash">
              <button class="filled-button">
                {{ "NAV_MENU.TRY_BUTTON" | translate }}
              </button>
            </a>
          </div>
        </div>
      </div>

      <ul class="navbar-nav ms-auto align-items-center" *ngIf="hideMenu">
        <li class="nav-item page">
          <button
            *ngIf="scrollToID"
            class="btn-gradient-danger"
            (click)="scrollToElement()"
          >
            {{ "NAV_MENU.CONTACT_US" | translate }}
          </button>
          <button
            *ngIf="!scrollToID"
            class="btn-gradient-danger"
            routerLink="/{{ currentLanguage }}/contact-us"
          >
            {{ "NAV_MENU.CONTACT_US" | translate }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</nav>
