import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private translate: TranslateService,
    private location: Location
  ) { }

  metaData = [
    {
      "path": "",
      "data": {
        "en": {
          "title": "Integrated POS System and QR Menu Order for F&B | IDEKU",
          "desc": "Boost Sales & Simplify F&B Operations! Combine POS & QR menus with IDEKU for real-time data, faster service & happy customers. Get a FREE Demo today!"
        },
        "id": {
          "title": "Sistem POS dan QR Menu Order Untuk Bisnis F&B | IDEKU",
          "desc": "Dapatkan solusi F&B lengkap! IDEKU dengan POS system & QR menu untuk data real-time, layanan cepat, dan peningkatakn kepuasan pelanggan. Dapatkan Demo GRATIS!"
        },
        "zh_cn": {
          "title": "餐饮集成POS系统和QR菜单订购 | IDEKU",
          "desc": "提升销售，简化餐饮运营！为实时数据，更快的服务和满意的客户将与IDKEKKU结合POS和QR菜单。立即获得免费试用。"
        },
        "zh_tw": {
          "title": "餐飲業的整合型POS系統及QR菜單點餐 | IDEKU",
          "desc": "提升銷售並簡化餐飲營運！結合IDEKU的POS系統與QR菜單點餐系統，即時的數據、更快速的服務、滿意的客戶。今天就來免費體驗！"
        }
      }
    },
    {
      "path": "about-us",
      "data": {
        "en": {
          "title": "About Us - IDEKU, POS system and QR menu",
          "desc": "Learn more about IDEKU, a leading provider of POS systems and QR menu solutions. Discover our mission, values, and the innovative technology driving our success."
        },
        "id": {
          "title": "Tentang Kami - IDEKU, POS sistem dan QR menu",
          "desc": "Pelajari lebih lanjut tentang IDEKU, sistem POS dan solusi menu QR. Temukan misi, visi hingga produk dari IDEKU."
        },
        "zh_cn": {
          "title": "关于我们 - IDEKU、POS系统及QR菜单",
          "desc": "了解更多关于IDEKU信息，POS系统和QR菜单解决方案的领先。深入了解我们的使命，价值及推动我们成功的创新技术。"
        },
        "zh_tw": {
          "title": "關於我們 - IDEKU、POS系統及QR菜單",
          "desc": "了解更多關於IDEKU的資訊：POS系統與QR菜單點餐技術的領先企業。探索我們的使命、價值觀和驅動我們成功的創新技術。"
        }
      }
    },
    {
      "path": "career",
      "data": {
        "en": {
          "title": "Career - IDEKU Point of Sales and QR Menu",
          "desc": "Explore exciting career opportunities at IDEKU. Join our team and contribute to innovative POS and QR menu integration."
        },
        "id": {
          "title": "Karir - IDEKU Point of Sales",
          "desc": "Jelajahi peluang karir menarik di IDEKU. Bergabunglah dengan tim kami dan berkontribusi dalam solusi integrasi POS yang inovatif."
        },
        "zh_cn": {
          "title": "招聘信息 | IDEKU POS系统和QR菜单",
          "desc": "在IDEKU探索令人兴奋的职业机会。加入我们的团队，为创新的POS系统和QR菜单整合。"
        },
        "zh_tw": {
          "title": "職涯 | IDEKU POS系統與QR點餐 ",
          "desc": "探索在IDEKU的精彩就業機會。加入我們的團隊並一起強化POS系統與QR點餐整合。"
        }
      }
    },
    {
      "path": "contact-us",
      "data": {
        "en": {
          "title": "Contact Us - IDEKU Point of Sales",
          "desc": "Get in touch with IDEKU Point of Sales for inquiries, support, or more information about our POS solutions. We're here to help your business succeed."
        },
        "id": {
          "title": "Kontak Kami - IDEKU Point of Sales",
          "desc": "Hubungi IDEKU Point of Sales untuk pertanyaan, dukungan, atau informasi lebih lanjut tentang solusi POS kami. Kami siap membantu kesuksesan bisnis Anda."
        },
        "zh_cn": {
          "title": "联系我们 | IDEKU POS系统",
          "desc": "联系我们了解更多关于我们POS解决方案的信息。我们会帮助您的企业达成成功。"
        },
        "zh_tw": {
          "title": "聯繫我們 | IDEKU POS系統",
          "desc": "聯繫IDEKU服務據點進行諮詢或了解更多關於我們POS系統整合方案的資訊。我們的存在是幫助您達成事業上的成功。"
        }
      }
    },
    {
      "path": "terms-and-conditions",
      "data": {
        "en": {
          "title": "Terms & Conditions | IDEKU Point of Sales",
          "desc": "Read the Terms & Conditions of IDEKU to understand your rights and obligations. Learn about our policies on usage, privacy, and more. Stay informed and protected."
        },
        "id": {
          "title": "Syarat & Ketentuan | IDEKU Point of Sales",
          "desc": "Baca Syarat & Ketentuan IDEKU untuk memahami hak dan kewajiban Anda. Pelajari tentang kebijakan penggunaan, privasi. Agar terinformasi dan terlindungi."
        },
        "zh_cn": {
          "title": "条件 | IDEKU POS系统",
          "desc": "阅读IDEKU的条款和条件，深入了解您的权力和义务。了解我们关于使用、隐私等方面的政策。保持知情和保护。"
        }
      }
    },
    {
      "path": "privacy-policy",
      "data": {
        "en": {
          "title": "Privacy Policy | IDEKU Point of Sales",
          "desc": "Learn how IDEKU protects your privacy and manages your data."
        },
        "id": {
          "title": "Kebijakan Privasi | IDEKU Point of Sales",
          "desc": "Pelajari bagaimana IDEKU melindungi privasi Anda dan mengelola data Anda."
        },
        "zh_cn": {
          "title": "隐私政策 | IDEKU POS系统",
          "desc": "了解更多IDEKU如何保护您的隐私和管理您的数据。"
        },
        "zh_tw": {
          "title": "隱私政策 | IDEKU POS系統",
          "desc": "了解IDEKU如何保護您的隱私及管理您的數據。"
        }
      }
    },
    {
      "path": "idepage",
      "data": {
        "en": {
          "title": "Link in Bio Maker for Instagram & TikTok | IDEKU",
          "desc": "Level up your Instagram & TikTok profiles! IDEKU's bio link maker lets you create clickable & eye-catching bios. Start now!"
        },
        "id": {
          "title": "Link in Bio tool untuk Instagram & TikTok | IDEKU",
          "desc": "Buat bio link untuk Instagram & TikTok dengan IDEKU. Mulai sekarang!"
        },
        "zh_cn": {
          "title": "Instagram和TikTok的链接可以在Bio Maker上找到 | IDEKU",
          "desc": "升级您的Instagram和TikTok主页！IDEKU的链接制作允许您创建可点击和引人注目的主页。立即开始！"
        },
        "zh_tw": {
          "title": "Instagram及TikTok的個人連結製作 | IDEKU",
          "desc": "提升您的Instagram與TikTok個人簡介！IDEKU的個人連結製作工具讓您製作吸引人的個人簡介。立即開始！"
        }
      }
    },
    {
      "path": "idepos",
      "data": {
        "en": {
          "title": "Point of Sales Software for F&B Business | IDEKU",
          "desc": "Streamline your F&B operations with IDEKU's POS software. Boost sales, improve efficiency, and enhance customer experience. Get a FREE Demo today!"
        },
        "id": {
          "title": "Aplikasi POS untuk Bisnis F&B | IDEKU",
          "desc": "Tingkatkan efisiensi dan penjualan bisnis F&B Anda dengan software POS IDEKU. Dapatkan layanan cepat, hemat biaya, dan pelanggan puas. Gratis Demo!"
        },
        "zh_cn": {
          "title": "餐饮业销售点系统 | IDEKU",
          "desc": "使用IDEKU的POS系统简化您的餐饮运营。提升销售，提供效率，提升客户体验。立即获得免费试用！"
        },
        "zh_tw": {
          "title": "為餐飲業打造的POS系統 | IDEKU",
          "desc": "透過IDEKU的POS系統簡化您的餐飲營運。提升銷售、提高效率、增強客戶體驗。今天就來免費體驗！"
        }
      }
    },
    {
      "path": "faq",
      "data": {
        "en": {
          "title": "",
          "desc": ""
        },
        "id": {
          "title": "",
          "desc": ""
        },
        "zh_cn": {
          "title": "",
          "desc": ""
        },
        "zh_tw": {
          "title": "",
          "desc": ""
        }
      }
    },
    {
      "path": "idefood/idefood-pda",
      "data": {
        "en": {
          "title": "Real-Time Orders & POS Integration for F&B Business | IDEKU",
          "desc": "Simplify order management with IDEFOOD PDA. Track orders, access real-time reports, and boost efficiency. Get a FREE Demo today!"
        },
        "id": {
          "title": "Sistem Order Terintegrasi Dengan POS System | IDEKU",
          "desc": "Kelola order dan transaksi F&B Anda dengan mudah dan efisien menggunakan Sistem Order Terintegrasi POS IDEKU. Dapatkan Demo GRATIS!"
        },
        "zh_cn": {
          "title": "餐饮业的实时订单和POS整合 | IDEKU",
          "desc": "使用IDEFOOD PDA简化您的订单管理。跟踪订单，访问实时报告，提高效率。立即获得免费试用！"
        },
        "zh_tw": {
          "title": "餐飲業即時點餐及POS整合 | IDEKU",
          "desc": "用IDEFOOD PDA來簡化餐點訂單管理。追蹤訂單、查詢即時報告並提升效率。今天就來免費體驗！"
        }
      }
    },
    {
      "path": "blog",
      "data": {
        "en": {
          "title": "IDEKU Blog: Insights & Tips for F&B Businesses",
          "desc": "Explore industry trends, best practices, get updated features or news from IDEKU, and find actionable tips for your F&B business with IDEKU's insightful blog."
        },
        "id": {
          "title": "Blog IDEKU: Wawasan & Tips untuk Bisnis F&B",
          "desc": "Jelajahi tren industri, praktik terbaik, dapatkan fitur atau berita terbaru dari IDEKU, dan temukan tips praktis untuk bisnis F&B Anda."
        },
        "zh_cn": {
          "title": "IDEKU网志：为您的企业提供洞察",
          "desc": "通过IDEKU富有洞察力的博客，探索行业趋势、最佳实践，获取IDEKU的最新功能或新闻，并为您的餐饮业务找到可行的建议。"
        },
        "zh_tw": {
          "title": "IDEKU部落格：餐飲業洞察及訣竅",
          "desc": "探索產業趨勢、接受最優質的訓練，了解IDEKU的最新功能或新聞，並在IDEKU部落格找到對餐飲事業的實用建議。"
        }
      }
    },
    {
      "path": "schedule-demo",
      "data": {
        "en": {
          "title": "Schedule Free POS System Demo | IDEKU Point of Sales",
          "desc": "Schedule a free IDEKU demo and consultation. Let our experts show you how our F&B solutions can improve your operations and delight your customers!"
        },
        "id": {
          "title": "Jadwalkan Demo GRATIS Sistem POS dengan IDEKU",
          "desc": "Jadwalkan demo gratis dan konsultasi IDEKU. Biarkan para ahli kami menunjukkan bagaimana solusi F&B kami dapat meningkatkan operasi Anda dan memuaskan pelanggan Anda!"
        },
        "zh_cn": {
          "title": "预约免费POS系统体验 | IDEKU POS系统",
          "desc": "预约免费IDEKU体验和查询。让我们的专家向您展示我们的餐饮解决方案，如何改善您的运营并提供您的客户满意度！"
        },
        "zh_tw": {
          "title": "預約免費POS系統體驗 | IDEKU POS系統",
          "desc": "預約免費的IDEKU體驗和諮詢。讓我們的專家為您介紹我們的餐飲業整合方案可以如何改善營運並讓客戶更加滿意！"
        }
      }
    },
    {
      "path": "promo",
      "data": {
        "en": {
          "title": "",
          "desc": ""
        },
        "id": {
          "title": "",
          "desc": ""
        },
        "zh_cn": {
          "title": "",
          "desc": ""
        },
        "zh_tw": {
          "title": "",
          "desc": ""
        }
      }
    },
    {
      "path": "pos-system-solution-singapore",
      "data": {
        "en": {
          "title": "POS System for Singapore | IDEKU Point of Sales",
          "desc": "Discover IDEKU's top POS system for Singapore. Enhance your business operations with our advanced, reliable, and efficient POS solutions."
        },
        "id": {
          "title": "Sistem POS di Singapura | IDEKU Point of Sales",
          "desc": "Temukan solusi POS terbaik di Singapura dengan IDEKU. Tingkatkan operasi bisnis Anda dengan solusi POS kami yang canggih, andal, dan efisien."
        },
        "zh_cn": {
          "title": "新加坡POS系统 | IDEKU POS系统",
          "desc": "通过IDEKU探索新加坡顶级POS系统。通过我们先进、可靠、高效的POS解决方案，增强您的业务运营。"
        },
        "zh_tw": {
          "title": "新加坡POS系統 | IDEKU POS系統",
          "desc": "使用IDEKU，發現新加坡的頂級POS系統。利用我們先進、可靠且高效率的銷售解決方案來提升您的事業營運。"
        }
      }
    },
    {
      "path": "reseller-partner-form",
      "data": {
        "en": {
          "title": "Become a Reseller | IDEKU Point of Sales",
          "desc": "Join IDEKU as a reseller partner and offer top-notch POS solutions to your clients. Partner with us and grow your business with our support."
        },
        "id": {
          "title": "Bergabung Menjadi Reseller | IDEKU Point of Sales",
          "desc": "Jadi mitra reseller IDEKU dan tawarkan solusi POS terbaik untuk klien Anda. Bergabunglah dengan kami dan kembangkan bisnis Anda dengan dukungan kami."
        },
        "zh_cn": {
          "title": "加入我们，成为经销商 | IDEKU POS系统",
          "desc": "成为IDEKU销售点的经销商，为您的客户提供一流的POS解决方案。今天就加入我们，在我们的支持下发展您的业务。"
        },
        "zh_tw": {
          "title": "加入我們成為經銷商 | IDEKU POS系統",
          "desc": "成為IDEKU的經銷商，為您的客戶提供頂級的POS系統解決方案。今天就來加入我們，在我們的幫助下發展您的事業。"
        }
      }
    },
    {
      "path": "integration/delivery/grabfood",
      "data": {
        "en": {
          "title": "POS System Integrated with GrabFood | IDEKU POS",
          "desc": "Enhance your business with IDEKU POS system, seamlessly integrated with GrabFood. Streamline your sales process, boost efficiency and customer satisfaction."
        },
        "id": {
          "title": "Sistem POS Terintegrasi dengan GrabFood | IDEKU POS",
          "desc": "Tingkatkan bisnis Anda dengan sistem POS IDEKU yang terintegrasi dengan GrabFood. Permudah proses penjualan Anda, tingkatkan efisiensi dan kepuasan pelanggan."
        },
        "zh_cn": {
          "title": "与GrabFood整合的POS系统 | IDEKU POS",
          "desc": "通过IDEKU POS系统增强您的业务，并与GrabFood的无缝整合。简化您的销售流程，提高效率及客户的满意度。"
        },
        "zh_tw": {
          "title": "與GrabFood整合的POS系統 | IDEKU POS",
          "desc": "使用與GrabFood完美整合的IDEKU POS來提升您的事業。簡化銷售、提高效率並增強客戶滿意度。"
        }
      }
    },
    {
      "path": "idefood/idefood-qr",
      "data": {
        "en": {
          "title": "Digital QR Menu System for F&B | IDEKU",
          "desc": "Transform your F&B service with IDEKU's QR ordering system. Enhance customer experience, save on printing costs, and gain valuable insights. Get a FREE Trial today!"
        },
        "id": {
          "title": "Sistem Menu QR Digital untuk F&B | IDEKU",
          "desc": "Ubah layanan F&B Anda dengan sistem pemesanan QR IDEKU. Tingkatkan pengalaman pelanggan, hemat biaya cetak, dan dapatkan wawasan berharga. Dapatkan Coba GRATIS!"
        },
        "zh_cn": {
          "title": "餐饮业的数字QR菜单系统 | IDEKU",
          "desc": "通过IDEKU的QR订购系统会变您的餐饮服务。提升客户体验，节省打印成本，获得更有价值的数据。立即获得免费试用！"
        },
        "zh_tw": {
          "title": "為餐飲業打造的數位QR點餐系統 | IDEKU",
          "desc": "透過IDEKU的QR點餐系統改變您的餐飲服務。提升客戶體驗、節省印刷成本並且獲得寶貴的數據分析。馬上就來免費體驗！"
        }
      }
    },
    {
      "path": "ideque",
      "data": {
        "en": {
          "title": "Manage Your F&B Queue with QR System | IDEKU",
          "desc": "Optimize your F&B operations with a queue management system through QR. Manage customer flow, wait times, and improve dine-in experiences."
        },
        "id": {
          "title": "Kelola Antrian F&B Anda dengan Sistem QR | IDEKU",
          "desc": "Optimalkan operasi F&B Anda dengan sistem manajemen antrian melalui QR. Kelola alur pelanggan, waktu tunggu, dan tingkatkan pengalaman makan di tempat."
        },
        "zh_cn": {
          "title": "通过QR系统，管理您的餐饮排队 | IDEKU",
          "desc": "使用队列系统优化您的餐饮运营。通过QR及时管理您的客流、件数等待时间、还有提升堂吃体验。"
        },
        "zh_tw": {
          "title": "為餐飲業打造的QR候位管理系統 | IDEKU",
          "desc": "使用候位管理系統來優化您的餐飲事業操作。利用QR科技管理客流、減少等待時間並提升用餐體驗。"
        }
      }
    },
    {
      "path": "idekiosk",
      "data": {
        "en": {
          "title": "Digital Self-Service KIOSK Solutions | IDEKU",
          "desc": "Tired of long queues and inefficient ordering processes? Transform your customer experience with IDEKIOSK's digital self-service solutions. Contact us to learn more!"
        },
        "id": {
          "title": "Solusi KIOSK Pemesanan Mandiri Digital | IDEKU",
          "desc": "Kelelahan dengan antrian panjang dan proses pemesanan yang tidak efisien? Ubah pengalaman pelanggan Anda dengan solusi layanan mandiri digital IDEKIOSK. Hubungi kami untuk informasi lebih lanjut!"
        },
        "zh_cn": {
          "title": "数字自助订餐KIOSK解决方案 | IDEKU",
          "desc": "厌倦了长队和低效的订购流程？使用IDEKIOSK的数字自助服务会彻底改变您的客户的体验。联系我们了解更多！"
        },
        "zh_tw": {
          "title": "數位自助點餐解決方案 | IDEKU",
          "desc": "厭倦長時間的等待以及低效率的點餐流程？使用IDEKIOSK的數位自助點餐系統改革您的客戶體驗。聯繫我們了解更多！"
        }
      }
    },
    {
      "path": "kds-eds",
      "data": {
        "en": {
          "title": "Kitchen Display & Expedite Display System Solutions | IDEKU",
          "desc": "Unlock your kitchen’s potential with IDEKU's kitchen display and expedite display systems. Improve efficiency, reduce wait times, and enhance communication. Get a FREE Trial today!"
        },
        "id": {
          "title": "Solusi Sistem Tampilan Dapur & Ekspedisi | IDEKU",
          "desc": "Buka potensi dapur Anda dengan sistem tampilan dapur dan ekspedisi IDEKU. Tingkatkan efisiensi, kurangi waktu tunggu, dan tingkatkan komunikasi. Dapatkan Coba GRATIS!"
        },
        "zh_cn": {
          "title": "厨房展示与加急展示系统解决方案 | IDEKU",
          "desc": "使用IDEKU的厨房显示系统和出餐管理系统解锁您的厨房功能。提高效率，减少等待时间，改善沟通。立即获得免费试用！"
        },
        "zh_tw": {
          "title": "廚房顯示系統與出餐管理系統解決方案 | IDEKU",
          "desc": "使用IDEKU的廚房顯示系統(KDS)與出餐管理系統(EDS)解鎖廚房的強大功能。提高效率、減少等待時間並改善溝通。今天就來免費體驗！"
        }
      }
    },
    {
      "path": "integration/payment/stripe",
      "data": {
        "en": {
          "title": "Payment Solutions for F&B | IDEKU",
          "desc": "Easily and securely accept all forms of payment. IDEKU’s integrated payment solutions support your F&B operations. Learn more!"
        },
        "id": {
          "title": "Solusi Pembayaran untuk F&B | IDEKU",
          "desc": "Terima semua bentuk pembayaran dengan mudah dan aman. Solusi pembayaran terintegrasi IDEKU mendukung operasi F&B Anda. Pelajari lebih lanjut!"
        },
        "zh_cn": {
          "title": "餐饮业支付解决方案 | IDEKU",
          "desc": "轻松安全地接受所有付款。IDEKU的整合支付解决方案为您的餐饮运营提供支持。了解更多！"
        },
        "zh_tw": {
          "title": "餐飲付款整合方案 | IDEKU",
          "desc": "輕鬆且安全地接受所有主要的付款方式。IDEKU的付款整合方案強化您的餐飲事業。了解更多！"
        }
      }
    },
    {
      "path": "sistem-pos-fnb-terbaik-indonesia",
      "data": {
        "en": {
          "title": "Sistem POS Terbaik di Indonesia - Idepos Solusi Integrasi untuk Bisnis FnB",
          "desc": "Solusi sistem POS terbaik di Indonesia untuk mengelola bisnis Anda dengan efisien. Nikmati fitur canggih, integrasi mudah, dan dukungan profesional untuk meningkatkan efisiensi dan layanan pelanggan."
        },
        "id": {
          "title": "Sistem POS Terbaik di Indonesia - Idepos Solusi Integrasi untuk Bisnis FnB",
          "desc": "Solusi sistem POS terbaik di Indonesia untuk mengelola bisnis Anda dengan efisien. Nikmati fitur canggih, integrasi mudah, dan dukungan profesional untuk meningkatkan efisiensi dan layanan pelanggan."
        },
        "zh_cn": {
          "title": "Sistem POS Terbaik di Indonesia - Idepos Solusi Integrasi untuk Bisnis FnB",
          "desc": "Solusi sistem POS terbaik di Indonesia untuk mengelola bisnis Anda dengan efisien. Nikmati fitur canggih, integrasi mudah, dan dukungan profesional untuk meningkatkan efisiensi dan layanan pelanggan."
        },
        "zh_tw": {
          "title": "Sistem POS Terbaik di Indonesia - Idepos Solusi Integrasi untuk Bisnis FnB",
          "desc": "Solusi sistem POS terbaik di Indonesia untuk mengelola bisnis Anda dengan efisien. Nikmati fitur canggih, integrasi mudah, dan dukungan profesional untuk meningkatkan efisiensi dan layanan pelanggan."
        }
      }
    },
    {
      "path": "SISTEM_POS_BISNIS_FNB_IDECAR",
      "data": {
        "en": {
          "title": "IDEKU POS: Sistem POS Terbaik untuk Restoran dan Kafe",
          "desc": "Optimalkan operasional bisnis F&B Anda dengan IDEKU POS, solusi untuk F&B. Tingkatkan efisiensi restoran Anda dengan fitur manajemen stok, laporan real-time, dan integrasi pembayaran QR."
        },
        "id": {
          "title": "IDEKU POS: Sistem POS Terbaik untuk Restoran dan Kafe",
          "desc": "Optimalkan operasional bisnis F&B Anda dengan IDEKU POS, solusi untuk F&B. Tingkatkan efisiensi restoran Anda dengan fitur manajemen stok, laporan real-time, dan integrasi pembayaran QR."
        },
        "zh_cn": {
          "title": "IDEKU POS: Sistem POS Terbaik untuk Restoran dan Kafe",
          "desc": "Optimalkan operasional bisnis F&B Anda dengan IDEKU POS, solusi untuk F&B. Tingkatkan efisiensi restoran Anda dengan fitur manajemen stok, laporan real-time, dan integrasi pembayaran QR."
        },
        "zh_tw": {
          "title": "IDEKU POS: Sistem POS Terbaik untuk Restoran dan Kafe",
          "desc": "Optimalkan operasional bisnis F&B Anda dengan IDEKU POS, solusi untuk F&B. Tingkatkan efisiensi restoran Anda dengan fitur manajemen stok, laporan real-time, dan integrasi pembayaran QR."
        }
      }
    }
  ]

  pathUrlList = [
    "/",
    "/about-us",
    "/career",
    "/contact-us",
    "/terms-and-conditions",
    "/privacy-policy",
    "/idepage",
    "/idepos",
    "/faq",
    "/idefood/idefood-pda",
    "/blog",
    "/schedule-demo",
    "/promo",
    "/pos-system-solution-singapore",
    "/reseller-partner-form",
    "/integration/delivery/grabfood",
    "/idefood/idefood-qr",
    "/ideque",
    "/idekiosk",
    "/kds-eds",
    "/integration/payment/stripe"
  ]

  setMetaTags(path: string, domain: string): void {
    const cleanedPath = path.replace(/^\/[^\/]+\/(.+)/, '$1');
    const result = path.split('/');  // Hasil: ['', 'en', 'about-us']
    const match = path.match(/^\/([^\/]+)/);
    let language = match ? match[1] : 'en'; // Mengambil nilai 'en'

    if (result[1] === 'zh-CN') {
      language = 'zh_cn'
    } else if (result[1] === 'zh-TW') {
      language = 'zh_tw'
    } else if (result[1] === 'id') {
      language = 'id'
    } else {
      language = 'en'
    }

    const pageData = this.metaData.find(meta => {
      if (cleanedPath === '/' || cleanedPath === '/en' || cleanedPath === '/id' || cleanedPath === '/zh-CN' || cleanedPath === '/zh-TW') {
        return true
      }

      // if (this.pathUrlList.includes(cleanedPath)) {
      //   return cleanedPath === meta.path
      // }
      return cleanedPath === meta.path
    }) as any;

    console.debug(cleanedPath, language, pageData, path, this.pathUrlList.includes('/ideque'))

    if (pageData) {
      const { title, desc } = pageData.data[language] || {};
      if (title && desc) {
        this.titleService.setTitle(title);
        this.metaService.updateTag({ name: 'title', content: title });
        this.metaService.updateTag({ name: 'description', content: desc });

        // Open Graph / Facebook meta tags
        this.metaService.updateTag({ property: 'og:type', content: 'website' });
        this.metaService.updateTag({ property: 'og:url', content: `https://${domain ?? 'www.ideku.io'}` });
        this.metaService.updateTag({ property: 'og:title', content: title });
        this.metaService.updateTag({ property: 'og:description', content: desc });
        this.metaService.updateTag({ property: 'og:image', content: `https://${domain ?? 'www.ideku.io'}/assets/images/logo/logo-ideku-box.png` });

        // Twitter meta tags
        this.metaService.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
        this.metaService.updateTag({ name: 'twitter:url', content: `https://${domain ?? 'www.ideku.io'}` });
        this.metaService.updateTag({ name: 'twitter:title', content: title });
        this.metaService.updateTag({ name: 'twitter:description', content: desc });
        this.metaService.updateTag({ name: 'twitter:image', content: `https://${domain ?? 'www.ideku.io'}/assets/images/logo/logo-ideku-box.png` });
      } else {
        // Set default meta if specific data is not found
        this.titleService.setTitle(title);
        this.metaService.updateTag({ name: 'title', content: this.metaData?.[0].data.en.title });
        this.metaService.updateTag({ name: 'description', content: this.metaData?.[0].data.en.desc });

        // Open Graph / Facebook meta tags
        this.metaService.updateTag({ property: 'og:type', content: 'website' });
        this.metaService.updateTag({ property: 'og:url', content: `https://${domain ?? 'www.ideku.io'}` });
        this.metaService.updateTag({ property: 'og:title', content: this.metaData?.[0].data.en.title });
        this.metaService.updateTag({ property: 'og:description', content: this.metaData?.[0].data.en.desc });
        this.metaService.updateTag({ property: 'og:image', content: `https://${domain ?? 'www.ideku.io'}/assets/images/logo/logo-ideku-box.png` });

        // Twitter meta tags
        this.metaService.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
        this.metaService.updateTag({ name: 'twitter:url', content: `https://${domain ?? 'www.ideku.io'}` });
        this.metaService.updateTag({ name: 'twitter:title', content: this.metaData?.[0].data.en.title });
        this.metaService.updateTag({ name: 'twitter:description', content: this.metaData?.[0].data.en.desc });
        this.metaService.updateTag({ name: 'twitter:image', content: `https://${domain ?? 'www.ideku.io'}/assets/images/logo/logo-ideku-box.png` });
      }
    }
  }

  updateKeywords() {
    this.translate.get('SEO.KEYWORDS_ALL').subscribe((keywords: string) => {
      this.metaService.updateTag({ name: 'keywords', content: 'pos system, point of sale systems, QR menu, food and beverages pos, pos software, aplikasi kasir, sistem pos, restaurant pos, cafe pos, inventory management, aplikasi POS, queue management system, self service kiosk, kitchen display system, expedite systems, instagram bio link tool' });
    });
  }

  updateCanonicalURL() {
    this.metaService.removeTag('rel="canonical"');
    const canonicalURL = this.getCanonicalURL();
    this.metaService.updateTag({ rel: 'canonical', href: canonicalURL });
  }

  getCanonicalURL(): string {
    const path = this.location.path().split('?')[0];
    const baseUrl = window.location.origin;

    return `${baseUrl}${path}`;
  }
}
