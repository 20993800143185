import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, Input, PLATFORM_ID } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

interface MenuItem {
  label: string;
  link?: string;
  submenu?: MenuItem[]; // Ensure this is defined
  showSubmenu?: boolean; // Control submenu visibility
}

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  imports: [CommonModule, TranslateModule],
  standalone: true
})
export class DropdownComponent {
  @Input() menuItems: MenuItem[] = [];

  width: number = 0;
  isMobile: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platform: Object,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platform)) {
      this.width = this.document.body.clientWidth ?? 0;
      this.updateMobileState();
    }
  }

  showSubmenu(item: MenuItem) {
    if (!this.isMobile) {
      item.showSubmenu = true;
    }
  }

  hideSubmenu(item: MenuItem) {
    if (!this.isMobile) {
      item.showSubmenu = false;
    }
  }

  updateMobileState() {
    this.isMobile = this.width < 992;
    // Show all submenus on mobile
    this.menuItems.forEach(item => {
      item.showSubmenu = this.isMobile; // Show all submenus if mobile
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.width = window.innerWidth;
    this.updateMobileState();
  }
}
